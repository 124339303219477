import {
  EXLY_SCHEDULELIST__REQUEST_DRAFT_OFFERINGS,
  EXLY_SCHEDULELIST__REQUEST_LIVE_OFFERINGS,
  EXLY_SCHEDULELIST__REQUEST_PAST_OFFERINGS,
  EXLY_SCHEDULELIST__REQUEST_SOLDOUT_OFFERINGS,
  EXLY_SCHEDULE__GET_ALL_OFFERINGS,
} from "ui/pages/schedule/redux/actions";
import React from "react";

export const schedule_types_ids = {
  one_on_one_appointment: 0,
  one_on_one_class: 1,
  group_class: 2,
  workshop: 3,
  no_schedule: 4,
  content: 5,
  merchandise: 6,
  rolling_class: 7,
  telegram: 8,
  webinar: 9,
  whatsapp: 10,
  branded_community: 11,
};

export const schedule_type_names = {
  [schedule_types_ids.one_on_one_appointment]: "One-on-One appointment",
  [schedule_types_ids.one_on_one_class]: "One-on-One Class",
  [schedule_types_ids.group_class]: "Group Class",
  [schedule_types_ids.workshop]: "Workshop",
  [schedule_types_ids.no_schedule]: "Quick Payment Page",
  [schedule_types_ids.content]: "Content: Videos/ Files",
  [schedule_types_ids.merchandise]: "Merchandise",
  [schedule_types_ids.rolling_class]: "Flexible Class",
  [schedule_types_ids.telegram]: "Telegram Community",
  [schedule_types_ids.webinar]: "Live Webinars",
  [schedule_types_ids.whatsapp]: "Whatsapp Community",
  [schedule_types_ids.branded_community]: "Branded Community",
};

export const SCHEDULE_OFFERING_TYPE_NAMES = {
  [schedule_types_ids.one_on_one_appointment]:
    schedule_type_names[schedule_types_ids.one_on_one_appointment],
  [schedule_types_ids.one_on_one_class]:
    schedule_type_names[schedule_types_ids.one_on_one_class],
  [schedule_types_ids.group_class]:
    schedule_type_names[schedule_types_ids.group_class],
  [schedule_types_ids.workshop]:
    schedule_type_names[schedule_types_ids.workshop],
  [schedule_types_ids.rolling_class]:
    schedule_type_names[schedule_types_ids.rolling_class],
  [schedule_types_ids.webinar]: schedule_type_names[schedule_types_ids.webinar],
};

export const NON_SCHEDULE_OFFERING_TYPE_NAMES = {
  [schedule_types_ids.no_schedule]:
    schedule_type_names[schedule_types_ids.no_schedule],
  [schedule_types_ids.content]: schedule_type_names[schedule_types_ids.content],
  [schedule_types_ids.merchandise]:
    schedule_type_names[schedule_types_ids.merchandise],
  [schedule_types_ids.telegram]:
    schedule_type_names[schedule_types_ids.telegram],
  [schedule_types_ids.whatsapp]:
    schedule_type_names[schedule_types_ids.whatsapp],
  [schedule_types_ids.branded_community]:
    schedule_type_names[schedule_types_ids.branded_community],
};

export const COMMUNITY = {
  title: "Community",
  id: "community",
  sku_list: {
    branded_community: {
      id: "branded_community",
      image: "branded_community.png",
      title: (
        <div className={`d-flex align-items-center`}>
          Branded Community{" "}
          <img
            src={require("../assets/vectors/auto_awesome_filled.svg")}
            alt="auto_awesome"
            style={{ marginLeft: 5, height: 14, width: 14 }}
          />
        </div>
      ),
      description:
        "Create your own communities to engage your audience and upsell your offerings",
      cta_target_url: `CreateListing/${schedule_types_ids.branded_community}`,
      sku_id: schedule_types_ids.branded_community,
    },
    whatsapp: {
      id: "whatsapp",
      image: "whatsapp.png",
      title: "Whatsapp Community",
      description: "Create a Whatsapp group community  and drive growth",
      cta_target_url: `CreateListing/${schedule_types_ids.whatsapp}`,
      sku_id: schedule_types_ids.whatsapp,
    },
    telegram: {
      id: "telegram",
      image: "telegram.png",
      title: "Paid Telegram Communities",
      description: "Create Telegram communities and enable membership rules",
      cta_target_url: `CreateListing/${schedule_types_ids.telegram}`,
      sku_id: schedule_types_ids.telegram,
    },
  },
};

export const plan_type = {
  offering: "Offering",
};

export const OFFERING_STATUS_ENUM = {
  draft: "draft",
  live: "live",
  soldout: "soldout",
  past: "past",
  all_offerings: "allOfferings",
};

export const OFFERING_STATUSES = {
  [OFFERING_STATUS_ENUM.draft]: {
    key: OFFERING_STATUS_ENUM.draft,
    value: 0,
    action: EXLY_SCHEDULELIST__REQUEST_DRAFT_OFFERINGS,
  },
  [OFFERING_STATUS_ENUM.live]: {
    key: OFFERING_STATUS_ENUM.live,
    value: 1,
    action: EXLY_SCHEDULELIST__REQUEST_LIVE_OFFERINGS,
  },
  [OFFERING_STATUS_ENUM.soldout]: {
    key: OFFERING_STATUS_ENUM.soldout,
    value: 2,
    action: EXLY_SCHEDULELIST__REQUEST_SOLDOUT_OFFERINGS,
  },
  [OFFERING_STATUS_ENUM.past]: {
    key: OFFERING_STATUS_ENUM.past,
    value: 3,
    action: EXLY_SCHEDULELIST__REQUEST_PAST_OFFERINGS,
  },
  [OFFERING_STATUS_ENUM.all_offerings]: {
    key: [OFFERING_STATUS_ENUM.all_offerings],
    action: EXLY_SCHEDULE__GET_ALL_OFFERINGS,
  },
};

export const OFFERING_STATUSES_LIST = [
  OFFERING_STATUS_ENUM.draft,
  OFFERING_STATUS_ENUM.live,
  OFFERING_STATUS_ENUM.soldout,
  OFFERING_STATUS_ENUM.past,
];

export const bonus_offerings_count_to_show = 3;

export const PAYMENT_PLAN_TYPE = {
  ONE_TIME_PAYMENT: 1,
  SUBSCRIPTIONS: 2,
  INSTALLMENTS: 3,
};

// @DEV this enum is used for Brandedcommunity & whatsapp community (it does not have installments)
export const payment_type = {
  // Payment Types
  LIFE_TIME_ACCESS: PAYMENT_PLAN_TYPE.ONE_TIME_PAYMENT,
  SUBSCRIPTION: PAYMENT_PLAN_TYPE.SUBSCRIPTIONS,
};

export const PRICE_PLANS = {
  paid: { value: "paid", label: "Paid" },
  free: { value: "free", label: "Free" },
};

export const DEFAULT_PAYMENT_CYCLE = 0;

export const webinarMaxSpot = 100000;

export const schedulingErrorConstants = {
  TARGET_APPOINTMENT_BOOKED: 1,
  HAS_OVERLAPPING_UNAVAILABILITY: 2,
  HAS_OVERLAPPING_SESSIONS: 3,
  CUSTOMER_BOOKING_NON_EXISTENT: 4,
  APPOINTMENT_SCHEDULING_POST_PAYMENT_NOT_ENABLED: 5,
};

export const BOOKINGS_STATUS_CODES_ENUM = {
  1: "PENDING",
  2: "RECEIVED",
  3: "TRANSFERRED",
  4: "REFUNDED",
  5: "CANCELLED",
  6: "AUTHORIZED",
  7: "PROCESSING",
  8: "FAILED",
  9: "SUBSCRIPTION_CREATED",
};

export const BOOKINGS_STATUS_CODES = {
  PENDING: {
    name: "Pending",
    id: 1,
    className: "",
  },
  RECEIVED: {
    name: "Active",
    id: 2,
    className: "pine_active",
  },
  TRANSFERRED: {
    name: "Transfered",
    id: 3,
    className: "",
  },
  REFUNDED: {
    name: "Cancelled",
    id: 4,
    className: "danger",
  },
  CANCELLED: {
    name: "Cancelled",
    id: 5,
    className: "danger",
  },
  AUTHORIZED: {
    name: "Authorized",
    id: 6,
    className: "",
  },
  PROCESSING: {
    name: "Pending",
    id: 7,
    className: "",
  },
  FAILED: {
    name: "Failed",
    id: 8,
    className: "",
  },
  SUBSCRIPTION_CREATED: {
    name: "Subscription Created",
    id: 9,
    className: "",
  },
};

export const RECEIPT_URL = "receipt_url";
export const booking_record_slug = "transaction_uuid";

// Access visibility feature for Classes, QPP and workshops
export const access_visibility_offering_types = [
  schedule_types_ids.group_class,
  schedule_types_ids.workshop,
  schedule_types_ids.no_schedule,
];

export const LISTING_MISCELLANEOUS_DATA_TYPES = {
  requirements: 1,
  highlights: 2,
  reviews: 3,
  order_bump: 4,
};

export const ZOOM_ENTERPRISE_USER = 2;

export const COURSE_END_CHOICE_ENUM = {
  3: { value: 3, label: "Days", sessionLabel: "Session" },
  1: { value: 1, label: "Weeks" },
  2: { value: 2, label: "Months" },
};

export const PRICING_MODE_KEYS = {
  oneTime: 1,
  monthly: 2,
};
