export const warningBroadcastDelivery = `Message(s) can take up to 2 hours to send from the scheduled time, depending on the recipient count.`;

export const creatorUnavailableErrorMessage =
  "Please Select a different date or time as you are unavailable for current date time";

export const POST_INSTALLMENT_UNLOCKING_CONTENT_CHANGE_MODAL_INFO =
  "Please setup section unlocking for the new section(s) in 'Step 2: Course details'. If not set, then all new added sections will get automatically linked to the initial payment";

export const SOMETHING_WENT_WRONG = "Something went wrong!";

export const SUBDOMAIN_VALIDATION_ERROR_MSG =
  "Sub domain can have only letters(a-z) or numbers(0-9) or hyphen(-) between them";

export const POST_INSTALLMENTS_UNLOCKING_PLAN_TYPE_NOTE =
  "Create instalments in ‘Step 3: Pricing’ to setup section unlocking as per those instalments";

export const POST_EXAM_CERTIFICATE_ISSUE_LESSON_DELETION_ERROR =
  "Are you sure you want to delete this lesson? Other course details in your certificate access would change accordingly.";

export const POST_EXAM_CERTIFICATE_ISSUE_SECTION_DELETION_ERROR =
  "Are you sure you want to delete this section. Other course details in your certificate access would change accordingly.";

export const FRONTEND_WATERMARKING_MEDIA_LIB_NOTE =
  "Only Videos and Images will be watermarked";

export const BACKEND_WATERMARKING_MEDIA_LIB_NOTE =
  "Only Videos, Images & PDF's will be watermarked";

export const FRONTEND_WATERMARKING_BOOKING_EXISTS_MEDIA_LIB_NOTE =
  "For existing customers, processing of images & pdf files can take from 2-3 minutes";

export const FRONTEND_WATERMARKING_NO_BOOKING_EXISTS_MEDIA_LIB_NOTE =
  "Once a customer purchases, processing of images & pdf files for them can take from 2-3 minutes.";
