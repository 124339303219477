import { is_empty } from "utils/validations";
import {
  file_format_keys,
  file_mime_types,
} from "../features/Common/modules/File/File.constants";
import { GOOGLE_DRIVE_ENABLED } from "./common.constants";
import {
  RECORDED_CONTENT_FILTER_VALUE_OPTION_KEYS,
  RECORDED_CONTENT_FILTER_VALUE_OPTIONS_LIST,
  RECORDED_CONTENT_FILTER_VALUE_OPTIONS_MAP,
  RECORDED_CONTENT_MEDIA_TYPES,
} from "features/Listings/constants/RecordedContent.constants";
const videoIcon = require("assets/vectors/recorded-content/mediaTypes/video.svg");
const imageIcon = require("assets/vectors/recorded-content/mediaTypes/image.svg");
const textIcon = require("assets/vectors/recorded-content/mediaTypes/text.svg");
const attachmentIcon = require("assets/vectors/recorded-content/mediaTypes/attachment.svg");
const linkIcon = require("assets/vectors/recorded-content/mediaTypes/link.svg");
const pdfIcon = require("assets/vectors/recorded-content/mediaTypes/pdf.svg");
const embedIcon = require("assets/vectors/recorded-content/mediaTypes/embed.svg");
const liveSessionIcon = require("assets/vectors/recorded-content/mediaTypes/live_session.svg");
const examIcon = require("assets/vectors/recorded-content/mediaTypes/exam.svg");
const formIcon = require("assets/vectors/recorded-content/mediaTypes/form.svg");

export const SECTION_UNLOCKING_TYPES = {
  IMMEDIATELY: 1,
  ON_SPECIFIC_DATE: 2,
  POST_START_DAYS: 3,
  POST_PREVIOUS_COMPLETION: 4,
  POST_INSTALLMENTS: 5,
};

export const UNLOCKING_OPTIONS = [
  {
    id: SECTION_UNLOCKING_TYPES.IMMEDIATELY,
    value: SECTION_UNLOCKING_TYPES.IMMEDIATELY,
    label: "Immediately",
    reviewLabel: "Immediately unlock each section",
  },
  {
    id: SECTION_UNLOCKING_TYPES.ON_SPECIFIC_DATE,
    value: SECTION_UNLOCKING_TYPES.ON_SPECIFIC_DATE,
    label: "On Specific Dates",
    reviewLabel: "Separately unlock each section",
  },
  {
    id: SECTION_UNLOCKING_TYPES.POST_START_DAYS,
    value: SECTION_UNLOCKING_TYPES.POST_START_DAYS,
    label: "After Certain Days of Starting Course",
    reviewLabel: "Separately unlock each section",
  },
  {
    id: SECTION_UNLOCKING_TYPES.POST_PREVIOUS_COMPLETION,
    value: SECTION_UNLOCKING_TYPES.POST_PREVIOUS_COMPLETION,
    label: "After learner has completed previous section",
    reviewLabel: "Separately unlock each section",
  },
  {
    id: SECTION_UNLOCKING_TYPES.POST_INSTALLMENTS,
    value: SECTION_UNLOCKING_TYPES.POST_INSTALLMENTS,
    label: "Based on payment of instalments",
    reviewLabel: "Based on payment of instalments",
  },
];

export const validityOptions = [
  { id: 0, value: 0, label: "Lifetime" },
  { id: 1, value: 1, label: "Fixed Number of Weeks Since Purchase" },
];

/**
 * NOTE: dont use html, downloadable, hyperlink
 * TODO: replace these keys everywhere and remove
 */
export const recorded_content_media_types = RECORDED_CONTENT_MEDIA_TYPES;

export const recorded_content_media_type_details = {
  [recorded_content_media_types.video]: { name: "Video", imgSrc: videoIcon },
  [recorded_content_media_types.image]: { name: "Image", imgSrc: imageIcon },
  [recorded_content_media_types.text]: { name: "Text", imgSrc: textIcon },
  [recorded_content_media_types.attachment]: {
    name: "File",
    imgSrc: attachmentIcon,
  },
  [recorded_content_media_types.link]: { name: "Link", imgSrc: linkIcon },
  [recorded_content_media_types.pdf]: { name: "PDF", imgSrc: pdfIcon },
  [recorded_content_media_types.embed]: {
    name: "Embed",
    imgSrc: embedIcon,
  },
  [recorded_content_media_types.exam]: {
    name: "Exam",
    imgSrc: examIcon,
  },
  [recorded_content_media_types.form]: {
    name: "Form",
    imgSrc: formIcon,
  },
  [recorded_content_media_types.liveSession]: {
    name: "Live session",
    imgSrc: liveSessionIcon,
  },
  [recorded_content_media_types.progress_tracking_form]: {
    name: "Progress Tracking Form",
    imgSrc: formIcon,
  },
};

export const change_states = { unchanged: 0, updated: 1, added: 2, deleted: 3 };

export const AssessmentTool = {
  speedExamLink: "https://admin.speedexam.net",
  instructions:
    "https://myscoottechpvtltd.freshdesk.com/support/solutions/articles/84000354058-how-to-create-an-assessment-for-courses-recorded-videos-files-listing",
  assessmentCodePlaceHolder:
    "<iframe src='https://candidate.speedexam.net/openquiz.aspx?quiz=B65D53C37D7C4025B726BCAAFF0BAEBB' marginheight=0 marginwidth=0 frameborder='0' height='100%' width='100%' style='position:absolute; top:0; left:0;' ></iframe>",
  assessmentToolPlaceHolder: "Give it a name e.g. Certification Test",
};

export const accepted_image_formats = [
  file_format_keys.jpeg,
  file_format_keys.jpg,
  file_format_keys.png,
  file_format_keys.webp,
  file_format_keys.gif,
  file_format_keys.svg,
];

export const accepted_non_image_formats = [
  // audio
  file_format_keys.aac,
  file_format_keys.m4a,
  file_format_keys.mp3,
  file_format_keys.wav,
  file_format_keys.wma,

  // compressed files
  file_format_keys.zip,

  // docs
  file_format_keys.csv,
  file_format_keys.doc,
  file_format_keys.docx,
  file_format_keys.pdf,
  file_format_keys.ppt,
  file_format_keys.xlsx,
];

export const all_accepted_formats = [
  ...accepted_non_image_formats,
  ...accepted_image_formats,
];

export const watermarkable_image_formats = [
  file_format_keys.jpeg,
  file_format_keys.jpg,
  file_format_keys.png,
];

export const max_uploadable_file_size_in_mb = 25;

export const media_lib_add_tabs_keys = {
  media_library: 1,
  google_drive: 2,
  video_hosting: 3,
  url: 4,
};

export const add_media_tabs_map = {
  [media_lib_add_tabs_keys.media_library]: {
    label: "Media Library",
    value: [media_lib_add_tabs_keys.media_library],
  },
  [media_lib_add_tabs_keys.google_drive]: {
    label: "Google Drive",
    value: [media_lib_add_tabs_keys.google_drive],
  },
  [media_lib_add_tabs_keys.video_hosting]: {
    label: "Video hosting",
    value: [media_lib_add_tabs_keys.video_hosting],
  },
  [media_lib_add_tabs_keys.url]: {
    label: "URL",
    value: [media_lib_add_tabs_keys.url],
  },
};

export const add_media_tabs_list = [
  add_media_tabs_map[media_lib_add_tabs_keys.media_library],
  add_media_tabs_map[media_lib_add_tabs_keys.google_drive],
  add_media_tabs_map[media_lib_add_tabs_keys.video_hosting],
  add_media_tabs_map[media_lib_add_tabs_keys.url],
];

export const getMediaTabsList = ({ disableVideoUpload }) => {
  const list = [add_media_tabs_map[media_lib_add_tabs_keys.media_library]];

  if (GOOGLE_DRIVE_ENABLED) {
    list.push(add_media_tabs_map[media_lib_add_tabs_keys.google_drive]);
  }

  if (!disableVideoUpload) {
    list.push(add_media_tabs_map[media_lib_add_tabs_keys.url]);
  }
  return list;
};

export const video_formats = [
  file_format_keys.avi,
  file_format_keys.mp4,
  file_format_keys.mpeg,
  file_format_keys.ogv,
  file_format_keys.ts,
  file_format_keys.webm,
  file_format_keys["3gp"],
  file_format_keys["3g2"],
  file_format_keys.mov,
];

export const all_formats = [...all_accepted_formats, ...video_formats];

export const google_upload_all_mime_types = all_formats.map(
  (fileKey) => file_mime_types[fileKey]
);

export const google_upload_all_except_video_mime_types =
  all_accepted_formats.map((fileKey) => file_mime_types[fileKey]);

export const DRM_VIDEO_STATUS = {
  QUEUED: 1,
  PROCESSING: 2,
  READY: 3,
  ERROR: 4,
  FAILED: 5,
};

export const DRM_STATUS_MESSAGES = {
  [DRM_VIDEO_STATUS.QUEUED]: "Video Watermarking is in Progress.",
  [DRM_VIDEO_STATUS.PROCESSING]: "Video Watermarking is in Progress.",
  [DRM_VIDEO_STATUS.READY]: "Ready",
  [DRM_VIDEO_STATUS.ERROR]:
    "Failed to process the Video, Please try adding it again.",
  [DRM_VIDEO_STATUS.FAILED]:
    "Failed to process the Video, Please try adding it again.",
};

export const getDrmStatusMessage = (status) =>
  DRM_STATUS_MESSAGES[status] ||
  "Please Save the Recorded Content to Add Watermark on Video.";

export const MAX_VIDEO_FILE_SIZE_ALLOWED_IN_GB = 1;

export function getVdoCipherIframeUrl(otp_settings) {
  if (is_empty(otp_settings)) return "";
  const { otp, playbackInfo } = otp_settings;
  return `https://player.vdocipher.com/v2/?otp=${otp}&playbackInfo=${playbackInfo}`;
}

export const filter_value_options_keys =
  RECORDED_CONTENT_FILTER_VALUE_OPTION_KEYS;

export const filter_value_options_map =
  RECORDED_CONTENT_FILTER_VALUE_OPTIONS_MAP;

export const filter_value_options_list =
  RECORDED_CONTENT_FILTER_VALUE_OPTIONS_LIST;

export const getMediaLibraryFilterOptions = ({ disableVideoUpload }) => {
  return filter_value_options_list.filter((item) => {
    if (!disableVideoUpload || item.label !== "Videos") {
      return item;
    }
  });
};

export const DRM_COMPATIBILITY_EXPLANATION_URL =
  "https://exlyapp.com/home/drm-compatibility-explanation";

export const summary_constants = {
  courseSummary: "Course Summary",
  saveVideo: "Save Video",
  cancel: "Cancel",
  next: "next",
};

export const content_selected_keys = {
  emptySelected: -1,
  firstSelected: 0,
};
